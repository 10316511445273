import React from "react"
import PropTypes from "prop-types"

import Footer from "../components/footer/footer"
import Header from "../components/header/header"

import "../assets/scss/main.scss"

const DefaultLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="header-placeholder" />
      {children}
      <Footer />
    </div>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.children,
  highlightIndex: PropTypes.number,
  setHighlightIndex: PropTypes.func,
}

export default DefaultLayout
