import React, { useState } from "react"
import PropTypes from "prop-types"

import RightArrowWhiteIcon from "../assets/images/review-arrow-right-white.svg"
import "./subscribeBar.scss"

const SubscribeBar = ({ parentClassName }) => {
  const [email, setEmail] = useState("")
  const [validEmail, setValidEmail] = useState(true)

  const checkValidity = (inputEMail) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        inputEMail
      )
    ) {
      setValidEmail(true)
      return true
    }
    setValidEmail(false)
    return false
  }

  const submitEmail = () => {
    if (checkValidity(email)) location.reload()
  }

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      if (checkValidity(email)) location.reload()
    }
  }

  return (
    <div className="subscribe-container">
      <div className={"subscribe " + parentClassName + "__subscribe"}>
        <input
          className="subscribe__input"
          type="email"
          placeholder="Subscribe to our Newsletter"
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleEnterKeyPress}
        />
        <span className="subscribe__submit" onClick={() => submitEmail()}>
          <img src={RightArrowWhiteIcon} />
        </span>
      </div>
      <p
        className={
          "subscribe-container__invalid-email-text" +
          (validEmail ? "" : " subscribe-container__invalid-email-text-visible")
        }
      >
        *Enter a valid email address
      </p>
    </div>
  )
}

SubscribeBar.propTypes = {
  parentClassName: PropTypes.string,
}

export default SubscribeBar
