import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import Navbar from "./navbar"
import Link from "../../utils/link"
import Drawer from "./drawer"

import PhoneIcon from "../../assets/images/phone-color.svg"
import InstagramIcon from "../../assets/images/instgram.svg"
import DrawerIcon from "../../assets/images/hamburger.svg"
import DarkLogoIcon from "../../assets/images/irocklogo_dark.svg"
import "./header.scss"

const Header = () => {
  const [showDrawer, setShowDrawer] = useState(false)

  const res = useStaticQuery(contactQuery)
  const { phone, instagram } = res.wpPost.acfcontactinfo.contactList[0]

  const closeDrawer = () => {
    setShowDrawer(false)
  }

  return (
    <header className="header">
      <Link className="header__logo" to="/">
        <img src={DarkLogoIcon}></img>
      </Link>

      <Navbar />

      <span className="header__contact-us">
        <a href={"tel:" + phone} className="header__contact-number">
          <img className="header__phone-icon" src={PhoneIcon} />
          <span>{phone}</span>
        </a>
        <a href={instagram}>
          <img className="header__instagram-icon" src={InstagramIcon} />
        </a>
        <Link className="header__contact" to="https://contact.irockfinishes.com.au/">
          Get in touch
        </Link>
      </span>

      <div
        onClick={() => {
          if (!showDrawer) {
            setShowDrawer(true)
          }
        }}
        className="header__drawer-button"
      >
        <img src={DrawerIcon} />
        {showDrawer ? <Drawer handleClose={closeDrawer} /> : null}
      </div>
    </header>
  )
}

Header.propTypes = {
  highlightIndex: PropTypes.number,
  setHighlightIndex: PropTypes.func,
}

const contactQuery = graphql`
  query {
    wpPost(title: { eq: "Contact Info" }) {
      acfcontactinfo {
        contactList {
          ... on WpPost_Acfcontactinfo_ContactList_Data {
            instagram
            phone
          }
        }
      }
    }
  }
`

export default Header
