import React from "react"
import PropTypes from "prop-types"

import "./dropdown.scss"

const DropDown = (props) => {
  const {
    handleShowMenu,
    linkClassName,
    setShowMenu,
    showMenu,
    dropDownClassName,
    parentClassName,
    onClick,
    dropDownItems,
    label,
  } = props

  const links = []

  for (let i = 0; i < dropDownItems.length; i++) {
    if (i < 2) {
      links.push(
        <a
          key={i}
          onClick={parentClassName === "drawer" ? onClick : null}
          className="dropdown__link"
          href={dropDownItems[i].url}
        >
          <div className={"dropdown__item " + dropDownClassName}>
            {dropDownItems[i].label}
          </div>
        </a>
      )
    } else {
      links.push(
        <a
          key={i}
          onClick={() => {
            if (parentClassName === "drawer") {
              onClick()
            }
          }}
          className="dropdown__link"
          href={dropDownItems[i].url}
        >
          <div className={"dropdown__item " + dropDownClassName}>
            {dropDownItems[i].label}
          </div>
        </a>
      )
    }
  }
  React.useEffect(() => {
    document.addEventListener("click", () => {
      setShowMenu(false)
    })
  }, [])
  return (
    <div className="dropdown__wrapper" onClick={handleShowMenu}>
      <div
        className={linkClassName}
        onClick={(e) => {
          e.stopPropagation()
          handleShowMenu()
        }}
      >
        {label}
      </div>

      {showMenu ? (
        <div className={"dropdown " + parentClassName + "__dropdown-container"}>
          {links}
        </div>
      ) : null}
    </div>
  )
}

DropDown.propTypes = {
  handleShowMenu: PropTypes.func,
  linkClassName: PropTypes.string,
  showMenu: PropTypes.bool,
  setShowMenu: PropTypes.func,
  dropDownClassName: PropTypes.string,
  parentClassName: PropTypes.string,
  onClick: PropTypes.func,
  setHighlightIndex: PropTypes.func,
  dropDownItems: PropTypes.array,
  label: PropTypes.string,
}

export default DropDown
