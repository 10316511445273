import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import Link from "../../utils/link"
import DropDown from "./dropdown"

import "./navbar.scss"

const NavItem = ({ to, title }) => {
  return (
    <span className="navbar__item">
      <Link
        activeClassName="navbar__link-active"
        className="navbar__link"
        to={to}
      >
        {title}
      </Link>
    </span>
  )
}

const Navbar = () => {
  const res = useStaticQuery(menuQuery)
  const finalMenu = []

  res.wpMenu.menuItems.nodes.map((item) => {
    if (item.parentId === null) {
      finalMenu.push(item)
    }
  })

  const [showMenu, setShowMenu] = useState(false)

  const handleShowMenu = () => {
    if (!showMenu) {
      setShowMenu(true)
    } else {
      setShowMenu(false)
    }
  }

  return (
    <nav className="navbar header__navbar">
      {finalMenu.map((item, index) => {
        return item.childItems.nodes.length === 0 ? (
          <NavItem to={item.url} title={item.label} key={index} />
        ) : (
          <DropDown
            linkClassName={
              "navbar__link " + (showMenu ? "dropdown__link-active" : "")
            }
            handleShowMenu={handleShowMenu}
            showMenu={showMenu}
            dropDownClassName="navbar__dropdown"
            parentClassName="navbar"
            dropDownItems={item.childItems.nodes}
            label={item.label}
            setShowMenu={setShowMenu}
            key={index}
          />
        )
      })}
    </nav>
  )
}

const menuQuery = graphql`
  query {
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          url
          label
          childItems {
            nodes {
              url
              label
            }
          }
          parentId
        }
      }
    }
  }
`

NavItem.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
}

Navbar.propTypes = {
  setHighlightIndex: PropTypes.func,
}

export default Navbar
