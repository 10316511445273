import React from "react"
import PropTypes from "prop-types"

const ServiceColumn = ({ title, services, last }) => {
  return (
    <div className={"services" + (last ? " services_top-margin" : "")}>
      <p className="services__title">{title}</p>
      {services && services.map((service, index) => {
        if (!service.url) return false
        if (service.label === title) return false
        return (
          <a key={index} href={service.url} className="services__link">
            {service.label}
          </a>
        )
      })}
    </div>
  )
}

export default ServiceColumn
