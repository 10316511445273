import React, { useState } from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal"
import { graphql, useStaticQuery } from "gatsby"

import Link from "../../utils/link"
import DropDown from "./dropdown"
import SubscribeBar from "../subscribeBar"

import LogoLightIcon from "../../assets/images/irocklogo_light.svg"
import PhoneIcon from "../../assets/images/phone-color.svg"
import InstagramIcon from "../../assets/images/instgram.svg"
import MailIcon from "../../assets/images/envelope-color.svg"
import CloseIcon from "../../assets/images/drawer_close.svg"
import DropdownArrowIcon from "../../assets/images/drawer_arrow.svg"
import "./drawer.scss"

const Drawer = ({ handleClose }) => {
  const [showMenu, setShowMenu] = useState(false)
  const res = useStaticQuery(menuQuery)
  const { phone, instagram, eMail } = res.wpPost.acfcontactinfo.contactList[0]
  const finalMenu = []

  res.wpMenu.menuItems.nodes.forEach((item) => {
    if (item.parentId === null) {
      finalMenu.push(item)
    }
  })

  const handleShowMenu = () => {
    setShowMenu(!showMenu)
  }

  return (
    <Fade bottom>
      <div className="drawer">
        <div className="drawer__header">
          <img src={LogoLightIcon} />
          <span onClick={handleClose}>
            <img src={CloseIcon} />
          </span>
        </div>

        <div className="drawer__nav">
          {finalMenu.map((item) => {
            return item.childItems.nodes.length === 0 ? (
              <div className="drawer__link-container">
                <Link
                  onClick={handleClose}
                  className="drawer__link"
                  to={item.url}
                >
                  {item.label}
                </Link>
              </div>
            ) : (
              <div
                onClick={() => handleShowMenu()}
                className="drawer__link-container drawer__dropdown-link-container"
              >
                <DropDown
                  linkClassName="drawer__link"
                  dropDownClassName="drawer__dropdown"
                  handleShowMenu={handleShowMenu}
                  showMenu={showMenu}
                  parentClassName="drawer"
                  onClick={handleClose}
                  dropDownItems={item.childItems.nodes}
                  label={item.label}
                />
                <span>
                  <img
                    className={
                      showMenu
                        ? "drawer__arrow-icon"
                        : "drawer__arrow-icon drawer__arrow-icon-rotate"
                    }
                    src={DropdownArrowIcon}
                  />
                </span>
              </div>
            )
          })}
          <div onClick={handleClose} className="drawer__link-container">
            <Link className="drawer__link" to="/contact-us">
              Contact Us
            </Link>
          </div>
        </div>

        <SubscribeBar parentClassName="drawer" />
        <div className="drawer__contact">
          <div className="drawer__icons-container">
            <a href={instagram}>
              <img className="drawer__contact-icon" src={InstagramIcon} />
            </a>
            <a href={"mailto:" + eMail}>
              <img className="drawer__contact-icon" src={MailIcon} />
            </a>
          </div>
          <a href={"tel:" + phone} className="drawer__icons-container">
            <img className="drawer__contact-icon" src={PhoneIcon} />
            <p>{phone}</p>
          </a>
        </div>
      </div>
    </Fade>
  )
}

Drawer.propTypes = {
  handleClose: PropTypes.func,
  setHighlightIndex: PropTypes.func,
}

const menuQuery = graphql`
  query {
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          url
          label
          childItems {
            nodes {
              url
              label
            }
          }
          parentId
        }
      }
    }
    wpPost(title: { eq: "Contact Info" }) {
      acfcontactinfo {
        contactList {
          ... on WpPost_Acfcontactinfo_ContactList_Data {
            instagram
            phone
            eMail
          }
        }
      }
    }
  }
`

export default Drawer
