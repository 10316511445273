import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import ServiceColumn from "./serviceColumn"

import FinishesIcon from "../../assets/images/irocklogo_finishes.svg"
import PhoneIcon from "../../assets/images/phone-color.svg"
import InstagramIcon from "../../assets/images/instgram.svg"
import MailIcon from "../../assets/images/envelope-color.svg"
import "./footer.scss"

const Footer = () => {
  const res = useStaticQuery(menuQuery)
  const { phone, instagram, eMail } = res.wpPost.acfcontactinfo.contactList[0]
  const titleOne = res.footerOne.menuItems.nodes
  const titleTwo = res.footerTwo.menuItems.nodes
  const titleThree = res.footerThree.menuItems.nodes
  const titleFour = res.footerFour.menuItems.nodes
  return (
    <footer className="footer">
      <div className="footer__logo-column">
        <img className="footer__logo" src={FinishesIcon} />

        <div className="footer__icons-container">
          <a href={"tel:" + phone} className="footer__icon">
            <img src={PhoneIcon} />
          </a>
          <a href={"mailto:" + eMail} className="footer__icon">
            <img src={MailIcon} />
          </a>
          <a href={instagram} className="footer__icon">
            <img src={InstagramIcon} />
          </a>
        </div>

        <p className="footer__copyright-text">
          &#169; Copyright iRock Finishes 2021, All rights reserved.
        </p>
        <a
          href="https://atollon.com.au/"
          className="footer__copyright-text"
          target="_blank"
          rel="noopener noreferrer"
        >
          Design. Atollon
        </a>
      </div>

      <div className="footer__services">
        <ServiceColumn title={titleOne[0].label} services={titleOne} />
        <ServiceColumn title={titleTwo[0].label} services={titleTwo} />
        <ServiceColumn
          last={true}
          title={titleThree[0].label}
          services={titleThree}
        />
        <ServiceColumn
          last={true}
          title={titleFour[0].label}
          services={titleFour}
        />
      </div>

      <p className="footer__copyright-text-mobile">
        &#169; Copyright iRock Finishes 2020, All rights reserved.
      </p>
      <a
        href="https://atollon.com.au/"
        target="_blank"
        rel="noopener noreferrer"
        className="footer__copyright-text-mobile-link"
      >
        Design. Atollon
      </a>
    </footer>
  )
}

const menuQuery = graphql`
  query {
    footerOne: wpMenu(name: { eq: "Footer One" }) {
      menuItems {
        nodes {
          label
          url
        }
      }
    }
    footerTwo: wpMenu(name: { eq: "Footer Two" }) {
      menuItems {
        nodes {
          label
          url
        }
      }
    }
    footerThree: wpMenu(name: { eq: "Footer Three" }) {
      menuItems {
        nodes {
          label
          url
        }
      }
    }
    footerFour: wpMenu(name: { eq: "Footer Four" }) {
      menuItems {
        nodes {
          label
          url
        }
      }
    }
    wpPost(title: { eq: "Contact Info" }) {
      acfcontactinfo {
        contactList {
          ... on WpPost_Acfcontactinfo_ContactList_Data {
            instagram
            phone
            eMail
          }
        }
      }
    }
  }
`

export default Footer
